import { createApp } from 'vue'
import App from './App.vue'
import { createRouter, createWebHistory } from 'vue-router'
import { library } from '@fortawesome/fontawesome-svg-core'
import VueLazyload from 'vue3-lazyload'
import { CheckIcon } from '@heroicons/vue/24/outline'
import './main.css';
import VueGtag from 'vue-gtag'



/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { faUserSecret, faCircleChevronLeft, faCircleChevronRight, faShareAlt } from '@fortawesome/free-solid-svg-icons'

/* add icons to the library */
library.add(faUserSecret)
library.add(faCircleChevronLeft)
library.add(faCircleChevronRight)
library.add(faShareAlt)
library.add(CheckIcon)

import Toast, { POSITION } from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";

const router = createRouter({
    history: createWebHistory(),
    routes: [
        { path: '/', name: 'NewLandingPage', component: () => import('./components/NewLandingPage.vue') },
        { path: '/login', name: 'LoginPage', component: () => import('./components/LoginPage.vue') },
        { path: '/models/', name: 'BotsPage', component: () => import('./components/BotsPage.vue') },
        { path: '/Botwizard', name: 'BotWizardPage', component: () => import('./components/BotsWizard.vue') },
        { path: '/main/:botId', name: 'MainPage', component: () => import('./components/MainPage.vue') },
        { path: '/main2/:botId', name: 'MainPage2', component: () => import('./components/MainPage2.vue') },
        { path: '/analytics/:botId', name: 'AnalyticsPage', component: () => import('./components/AnalyticsPage.vue') },
        { path: '/register', name: 'RegisterPage', component: () => import('./components/RegisterPage.vue') },
        { path: '/pricing', name: 'PricingPage', component: () => import('./components/PricingPage2.vue') },
        { path: '/tnc', name: 'Tnc', component: () => import('./components/TermsAndConditions.vue') },
        { path: '/privacypage', name: 'Privacy', component: () => import('./components/PrivacyPage.vue') },
        { path: '/HVAC', name: 'Privacy', component: () => import('./components/HVAC.vue') },
        // { path: '/newlandingpage', name: 'NewLandingPage', component: () => import('./components/NewLandingPage.vue') },
      
    ]
})


createApp(App).component('font-awesome-icon', FontAwesomeIcon).use(VueGtag, {
    config: { id: 'G-MQM6Z38XKB' }
  }).use(VueLazyload).use(router).use(Toast, {
    position: POSITION.TOP_RIGHT,
    duration: 3000,
}).mount('#app')


// ------------------------------------------------------------------------------


// Burger menus
document.addEventListener('DOMContentLoaded', function () {
    // open
    const burger = document.querySelectorAll('.navbar-burger');
    const menu = document.querySelectorAll('.navbar-menu');

    if (burger.length && menu.length) {
        for (var i = 0; i < burger.length; i++) {
            burger[i].addEventListener('click', function () {
                for (var j = 0; j < menu.length; j++) {
                    menu[j].classList.toggle('hidden');
                }
            });
        }
    }

    // close
    const close = document.querySelectorAll('.navbar-close');
    const backdrop = document.querySelectorAll('.navbar-backdrop');

    if (close.length) {
        for (i = 0; i < close.length; i++) {
            close[i].addEventListener('click', function () {
                for (var j = 0; j < menu.length; j++) {
                    menu[j].classList.toggle('hidden');
                }
            });
        }
    }

    if (backdrop.length) {
        for (i = 0; i < backdrop.length; i++) {
            backdrop[i].addEventListener('click', function () {
                for (var j = 0; j < menu.length; j++) {
                    menu[j].classList.toggle('hidden');
                }
            });
        }
    }
});





//----------------------------------------------------------------------------------


// var Tawk_API = Tawk_API || {};
// (function () {
//     var s1 = document.createElement("script"), s0 = document.getElementsByTagName("script")[0];
//     s1.async = true;
//     s1.src = 'https://embed.tawk.to/62c5a0e5b0d10b6f3e7b1c6c/1g79vp0pl';
//     s1.charset = 'UTF-8';
//     s1.setAttribute('crossorigin', '*');
//     s0.parentNode.insertBefore(s1, s0);
// })();

//-------------------------------------

(function (c, l, a, r, i, t, y) {
    c[a] = c[a] || function () { (c[a].q = c[a].q || []).push(arguments) };
    t = l.createElement(r); t.async = 1; t.src = "https://www.clarity.ms/tag/" + i;
    y = l.getElementsByTagName(r)[0]; y.parentNode.insertBefore(t, y);
})(window, document, "clarity", "script", "gnzx25dmss");



(function(d, s, u) {
    var js, fjs = d.getElementsByTagName(s)[0];
    js = d.createElement(s); js.async = true; js.src = u;
    fjs.parentNode.insertBefore(js, fjs);
})(document, 'script', 'https://tag.clearbitscripts.com/v1/pk_7fbe7a64d7f811186aa0fa3d959e9191/tags.js');
